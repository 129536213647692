class HoaDetachedDrawer extends HTMLElement {
	private identifier: string;
	private isOpen: boolean = false;

	public constructor() {
		super();

		this.identifier = this.dataset.identifier;
		this.addEventListener("drawer-toggle", this.onDrawerToggle.bind(this));
		this.addEventListener("click", this.onClick.bind(this));
	}

	public openDrawer() {
		this.isOpen = !this.isOpen;
		this.dispatchEvent(new Event("drawer-toggle", { bubbles: false }));
	}

	private onDrawerToggle() {
		const rootEl = this.querySelector<HTMLElement>(
			`[data-drawer-component="root"]`
		);

		if (!rootEl) {
			return;
		}

		if (this.isOpen) {
			rootEl.style.display = "block";
		} else {
			rootEl.style.display = "none";
		}
	}

	private onClick(event: Event) {
		const target = event.target as HTMLElement;
		if (target.dataset.drawerAction === "close") {
			this.openDrawer();
			return;
		}

		const closestCloseEl = target.closest("[data-drawer-action='close']");
		if (closestCloseEl) {
			this.openDrawer();
			return;
		}
	}
}

class HoaDetachedDrawerOpener extends HTMLElement {
	private readonly identifier: string;
	private readonly selector: string;

	public constructor() {
		super();
		this.identifier = this.dataset.identifier;
		this.selector = this.dataset.selector;

		this.addEventListener("click", this.onClick.bind(this));
	}

	private onClick(event: Event) {
		let matchingElement = false;
		this.querySelectorAll(this.selector).forEach(
			(element: HoaDetachedDrawer) => {
				if (element.contains(event.target as Node)) {
					matchingElement = true;
				}
			}
		);

		if (!matchingElement) {
			return;
		}

		const drawer = document.querySelector(
			`hoa-detached-drawer[data-identifier="${this.identifier}"]`
		) as HoaDetachedDrawer;

		if (!drawer) {
			return;
		}

		drawer.openDrawer();
	}
}

customElements.define("hoa-detached-drawer", HoaDetachedDrawer, {});

customElements.define("hoa-detached-drawer-opener", HoaDetachedDrawerOpener);
